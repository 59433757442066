.chatLogo {
  height: 64px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-size: 25px;
}
.date {
  color: #4b4b4b;
  font-weight: 500;
}

.unread {
  color: #0040ff;
  font-size: 17px;
}

.all-chat {
  width: auto;
  height: 83vh;
  overflow-y: scroll;
}
/* @media (max-width: 600px) {
    .ant-layout-sider-children {
      width: 230px;
    }
    ant-layout-sider {
      position: relative;
      width: 230px !important;
    }
  } */
.all-chat::-webkit-scrollbar {
  width: 5px;
}
.all-chat::-webkit-scrollbar-thumb {
  background-color: rgb(177, 177, 177);
  border-radius: 6px;
}

.all-chat::-webkit-scrollbar-thumb:hover {
  background-color: #d8d4d4;
}
.all-chat::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.chat-search input {
  width: 90%;
}
.para p {
  margin-bottom: 0;
}
.toggle-wrap {
  padding: 6px !important;
}
.user {
  display: flex;
  background-color: white;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  transition: 0.2s ease;
  cursor: pointer;
  padding: 10px 5px 10px 5px;
}
.user .Namemessage {
  width: 125px;
}
.user:hover {
  background-color: #f7eeeef0;
}
.model-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.dummyUser {
  width: 30%;
  overflow: hidden;
}
.dummyUser p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
}

.user .dummyUser img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}
.input-of-modal {
  height: 63px;
  border: none;
  padding: 6px 10px;
  font-size: 20px;
}
.cancel-button-modal {
  height: 38px;
  background-color: black;
  color: white;
  border-radius: 5px;
}
/* model for createConversation  */
.ant-modal-mask {
  background-color: rgb(7 4 4 / 75%);
}
.ant-modal {
  width: 63% !important;
}
.mode-send-button {
  width: 80px;
  height: 40px;
  padding-top: 6px;
  margin: 0 !important;
  border: none;
  background-color: #ffe600;
  border-radius: 6px;
  transition: 0.3s ease;
}
.mode-send-button:disabled {
  opacity: 0.4;
}
.ant-modal-body {
  background: #e1e1e1;
  padding: 0;
}
.start-new-chat {
  display: flex;
  justify-content: space-between;
  background-color: #ffe600;
  padding: 2%;
  font-size: 22px;
  font-weight: bold;
  font-family: math;
}
.ant-space-align-center {
  gap: 15px !important;
  align-items: flex-end;
  padding: 13px;
  margin: 0 !important;
}
.div-for-input {
  padding: 0 15px 0px 15px;
}
.ant-modal-centered .ant-modal {
  top: -115px;
}
/* model for createConversation  */

.chat-data-here {
  height: 83vh;
  padding: 8px 20px;
  background-color: #e1e1e1;
}

.not-found {
  font-size: 22px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  font-weight: 500;
  color: darkslategray;
}
.ant-layout-header {
  padding: 0 20px;
  height: 47px !important;
  display: flex;
}
.chat-user-detail {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.single-chat-user {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  object-fit: cover;
}
.single-chat-phone {
  margin: 0;
  font-weight: bold;
}
.Side-img-name {
  display: flex;
  align-items: center;
  gap: 17px;
}
.chat-username {
  margin: 0;
}
.user2-data {
  text-align: end;
}
.para .date {
  font-size: 14px;
  color: #747474;
}
.active {
  background-color: #f7eeeef0;
}
.message {
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-start;
  margin-bottom: 10px;
}

.message-content {
  /* display: flex; */
  /* flex-direction: column; */
  /* grid-gap: 2px; */
  /* gap: 2px; */
  padding: 6px 5px;
  background-color: #ffffff;
  max-width: 70%;
  word-wrap: break-word;
}
.timestamp {
  /* display: block; */
  font-size: 0.8em;
  color: #939393;
  /* margin-top: 5px; */
  margin-left: 7px;
  position: relative;
  top: 3px;
}
.timestamp svg {
  font-size: 17px;
}
.message-read {
  color: #53bdeb;
}
.TimeandCheck {
  text-align: end;
  margin-top: -9px;
}
.sent .message-content {
  border-radius: 8px 0 8px 8px;
}
.sent {
  justify-content: flex-end;
  position: relative; /* Make sure the position is set to relative */
}
.received .message-content {
  border-radius: 0 8px 8px 8px;
}
.received {
  justify-content: flex-start;
  position: relative; /* Make sure the position is set to relative */
}
.sent .message-content::before {
  content: "";
  position: absolute;
  top: 0px;
  right: -8px;
  border-width: 1px 0px 9px 9px;
  border-style: solid;
  border-color: transparent transparent transparent #ffffff;
}

.received .message-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: -7px;
  border-width: 0px 8px 9px 0px;
  border-style: solid;
  border-color: transparent #ffffff transparent transparent;
}
.trigger {
  font-size: 23px;
  margin-top: 0px;
}
.trigger svg {
  font-size: 30px;
  margin: 0 5px 18px 0;
  cursor: pointer;
}
.empty-chat {
  text-align: center;
  font-size: 4vw;
  margin: auto 0;
}

/* style for dialog box  */
.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
.dialog-input-content {
  margin-top: 10px;
}
@media (max-width: 650px) {
  .false-collapesd {
    display: none;
  }
  .true-collapesd {
    display: flex;
  }
  .chat-aside {
    min-width: 100% !important;
  }
  .false-all-caht {
    display: none;
  }
}
