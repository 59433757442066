.nav {
  background: rgb(255, 230, 0);
  height: 60px;
  display: flex;
  justify-content: space-between;
  /* padding: 0.2rem calc((100vw - 1000px) / 2); */
  z-index: 12;
}

.navLink {
  color: #000000 !important;
  font-size: 15px;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0 0.7rem;
  cursor: pointer;
  position: relative;
}
.navLink:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #007bff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.navLink:hover::after {
  color: black !important;
  width: 100%;
  left: 0;
}

.bars {
  display: none;
  color: #808080;
}

.navMenu {
  display: flex;
  align-items: center;
  margin-right: -24px;
}
.navLink.active::after {
	width: 100%;
	left: 0;
}
@media screen and (max-width: 768px) {
  .bars {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
}
